import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import { SEO } from '../components/SEO';
import { Tags } from "../components/Text";

const menuLinks = {
  "Recipes": "/recipes",
  "Tech Blog": "/blogs",
  'About Me': '/',
}

const IndexPage = ({ data }) => {
  const postHtml = data.post.html
  const postMeta = data.post.frontmatter
  const fluidImage = data.post.fields.image.childImageSharp.fluid

  return (
    <Layout fullMenu menuLinks={menuLinks}>
      <SEO title={postMeta.title} description={postMeta.title} />
      <article id="main">
        <section className="wrapper style_recipe">
          <div className="inner">
            <div className="meta">
              <div className="leftmeta">
                <h1 className="recipe-title">{postMeta.title}</h1>
                <p className="author">By {postMeta.author}</p>
                <p><i>{postMeta.description}</i></p>
                <table>
                  <thead>
                    <tr>
                      <th><div className="icon fa-clock solid" />Time</th>
                      <th><div className="icon fa-users solid" />Servings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{postMeta.time} mins</td>
                      <td>{postMeta.servings}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="rightmeta">
                <Img fluid={fluidImage} alt={postMeta.title} />
              </div>
            </div>
            <hr />
            <div dangerouslySetInnerHTML={{ __html: postHtml }} />
            <br/><br/>
            <Tags backgroundColor={Tags.BackgroundColor.RED} labels={postMeta.tags} />
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        image {
          childImageSharp {
            fluid(maxWidth: 1280) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      frontmatter {
        title
        description
        author
        slug
        date
        image
        tags
        time
        servings
      }
    }
  }
`
